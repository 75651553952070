'use strict'

###*
 # @ngdoc object
 # @name mundoData
 # @description

###
angular
  .module 'mundoData', []
